<template>
  <div>
    <div class="row">
      <div class="col-xl-3 col-12 my-3">
        <label for=""> Nom </label>
        <input type="text" v-model="customer.lastName" class="form-control" />
      </div>

      <div class="col-xl-3 col-12 my-3">
        <label for=""> Prénom </label>
        <input type="text" v-model="customer.firstName" class="form-control" />
      </div>

      <div class="col-xl-3 col-12 my-3">
        <label for=""> Adresse </label>
        <input type="text" v-model="customer.address" class="form-control" />
      </div>

      <div class="col-xl-3 col-12 my-3">
        <label for=""> Raison Sociale </label>
        <input type="text" v-model="customer.society" class="form-control" />
      </div>

      <div class="col-xl-3 col-12 my-3">
        <label for=""> Ville </label>
        <input type="text" v-model="customer.city" class="form-control" />
      </div>

      <div class="col-xl-3 col-12 my-3">
        <label for=""> Code Postal </label>
        <input type="text" v-model="customer.ziPcode" class="form-control" />
      </div>

      <div class="col-xl-3 col-12 my-3">
        <label for="">Téléphone</label>
        <input type="text" v-model="customer.phone" class="form-control" />
      </div>

      <div class="col-xl-3 col-12 my-3">
        <label for="">Téléphone 2</label>
        <input type="text" v-model="customer.phone2" class="form-control" />
      </div>

      <div class="col-xl-3 col-12 my-3">
        <label for=""> Fix </label>
        <input type="text" v-model="customer.fix" class="form-control" />
      </div>

      <div class="col-xl-3 col-12 my-3">
        <label for="">E-mail</label>
        <input type="text" v-model="customer.email" class="form-control" />
      </div>

      <div
        
        class="col-xl-3 col-12 my-3"
      >
        <label for=""> ICE </label>
        <input type="text" v-model="customer.ice" class="form-control" />
      </div>

      <div  class="col-xl-3 col-12 my-3">
        <label for="">Type</label>
        <select
          name=""
          id=""
          v-model="customer.customerType_reference"
          class="form-select"
        >
          <option
            v-for="(customer_type, index) in customer_types"
            :key="index++"
            :value="customer_type.reference"
          >
            {{ customer_type.fullName }}
          </option>
        </select>
      </div>

      <div  class="col-xl-3 col-12 my-3">
        <label for="">Montant Impayé Maximum</label>
        <input type="text" v-model="customer.maxUnpaid" class="form-control" />
      </div>

      <div class="col-xl-3 col-12 my-3">
        <label for=""> Latitude </label>
        <input type="text" v-model="customer.latitude" class="form-control" />
      </div>

      <div class="col-xl-3 col-12 my-3">
        <label for=""> Longitude </label>
        <input type="text" v-model="customer.longitude" class="form-control" />
      </div>

      <div  class="col-xl-3 col-12 my-3">
        <label for=""> Responsable </label>
        <select
          
          v-model="customer.employee_reference"
          class="form-select"
        >
          <option value=""></option>
          <option
            v-for="(employee, index) in employees"
            :key="index++"
            :value="employee.reference"
          >
            {{ employee.fullName }}
          </option>
        </select>
      </div>


      <div class="col-xl-12">
        <label for=""> Remarque </label>
        <textarea
          v-model="customer.remark"
          class="form-control"
          cols="30"
          rows="3"
        ></textarea>
      </div>
    </div>

    <div class="row mt-3 justify-content-between">
      <div class="col-auto"></div>
      <div class="col-auto">
        <button class="btn btn-success" @click="save(customer)">
          <i class="bi bi-check-square-fill"></i> Modifer & Enregistrer
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      // customer: {},
    };
  },
  computed: {
    ...mapGetters("customer", {
      customer_types: "getCustomerTypes",
      customer: "getCustomer",
    }),
    ...mapGetters("employee", {
      employees: "getAll",
    }),
  },
  methods: {
    async save(data) {
      await this.$store.dispatch("customer/update", data);
    },
    async empty() {
      this.$store.commit("customer/setCustomer", {});
    },
  },
  beforeMount() {
    this.$store.dispatch("customer/getCustomerTypes");
    this.$store.dispatch("customer/show", this.$route.params.reference);
     this.$store.dispatch("employee/getAll");
  },
};
</script>
